<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="options",
    style="width: 100%; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartStackColBar",
  props: {
    barDataList: Array,
    categories: Array,
    title: String,
    barTooltipObject: Object,
  },
  data() {
    var vm = this;
    return {
      max: 100,
      chartOptions: {
        chart: {
          type: "bar",
        },
        title: {
          text: this.title,
        },
        xAxis: {
          categories: this.categories,
          lineWidth: 0.5,
          gridLineWidth: 1,
        },
        yAxis: {
          min: 0,
          title: {
            text: "次數",
            y: 10,
            // x: 70,
            // align: "high",
            // textAlign: "right",
          },
          lineWidth: 0.5,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          // visible: false,
        },
        legend: {
          reversed: true,
          align: "right",
          x: 0,
          y: 0,
          verticalAlign: "top",
          // itemMarginTop: 20,
          itemMarginBottom: 10,
          floating: false,
          backgroundColor: "white",
          // borderColor: "#CCC",
          // borderWidth: 1,
          shadow: false,
        },

        tooltip: {
          formatter: function () {
            let text = "";
            for (let index in this.points) {
              let temp = this.points[index];
              if (text == "") {
                text = text + temp.x + "<br/>";
              }
              if (temp.series.name == "禁區") {
                let tempMake = "";
                if (temp.point.index == 0) {
                  tempMake = vm.barTooltipObject.home_paint;
                } else {
                  tempMake = vm.barTooltipObject.away_paint;
                }
                text =
                  text +
                  "<span style=color:" +
                  temp.series.color +
                  ">" +
                  temp.series.name +
                  "</span>:" +
                  temp.y +
                  "</b>" +
                  "投" +
                  tempMake +
                  "中" +
                  "<br/>";
              } else if (temp.series.name == "外線空擋") {
                let tempMake = "";
                if (temp.point.index == 0) {
                  tempMake = vm.barTooltipObject.home_open;
                } else {
                  tempMake = vm.barTooltipObject.away_open;
                }
                text =
                  text +
                  "<span style=color:" +
                  temp.series.color +
                  ">" +
                  temp.series.name +
                  "</span>:" +
                  temp.y +
                  "</b>" +
                  "投" +
                  tempMake +
                  "中" +
                  "<br/>";
              } else if (temp.series.name == "外線干擾") {
                let tempMake = "";
                if (temp.point.index == 0) {
                  tempMake = vm.barTooltipObject.home_contested;
                } else {
                  tempMake = vm.barTooltipObject.away_contested;
                }
                text =
                  text +
                  "<span style=color:" +
                  temp.series.color +
                  ">" +
                  temp.series.name +
                  "</span>:" +
                  temp.y +
                  "</b>" +
                  "投" +
                  tempMake +
                  "中" +
                  "<br/>";
              } else if (temp.series.name == "失誤") {
                text =
                  text +
                  "<span style=color:" +
                  temp.series.color +
                  ">" +
                  temp.series.name +
                  "</span>:" +
                  temp.y +
                  "</b>次<br/>";
              } else if (temp.series.name == "上罰球線") {
                text =
                  text +
                  "<span style=color:" +
                  temp.series.color +
                  ">" +
                  temp.series.name +
                  "</span>:" +
                  temp.y +
                  "</b>次<br/>";
              }
            }
            return text;
          },
          shared: true,
        },
        series: [],
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        },

        plotOptions: {
          bar: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
              color: "white",
              // format: "{point.y}次",
              style: {
                // textOutline: "1px 1px black",
                textOutline: "none",
              },
            },
          },
          series: {
            borderWidth: 0,
            // dataLabels: {
            //   enabled: true,
            //   format: "{point.y:.1f}%",
            // },
          },
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  computed: {
    options() {
      let options = Object.assign(this.chartOptions, {});

      options.series = [];
      options.xAxis.categories = Object.assign(this.categories, {});
      options.series = Object.assign(this.barDataList, {});
      options.title.text = this.title;

      var sums = [0, 0]; //need equal categories length
      var max = 0;

      for (let i in this.barDataList) {
        for (let j in this.barDataList[i].data) {
          sums[j] += this.barDataList[i].data[j].y;
          if (sums[j] > max) {
            max = sums[j];
          }
        }
      }

      options.yAxis.max = max;

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}

table {
  margin: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

td,
th {
  border: 1px solid #ccc;
  padding: 4px 20px;
}
</style>
