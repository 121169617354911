<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="options",
    style="width: 100%; height: 400px;"
  )
</template>

<script>
export default {
  name: "HighChartBar",
  props: {
    barDataList: Array,
    categories: Array,
    title: String,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: this.categories,
        },
        yAxis: {
          title: {
            text: null,
          },
          crosshair: true,
          // labels: {
          //   format: "{value} %",
          // },
        },
        legend: {
          align: "right",
          x: 0,
          verticalAlign: "top",
          y: 0,
          floating: true,
          backgroundColor: "white",
          // borderColor: "#CCC",
          // borderWidth: 1,
          shadow: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span> {series.name}: <b>{point.y}',
        },
        series: this.barDataList,
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        },

        plotOptions: {
          cursor: "pointer",

          column: {
            dataLabels: {
              enabled: true,
              color: "#1f1f1f",
              style: {
                // 如果不需要数据标签阴影，可以将 textOutline 设置为 'none'
                // textOutline: "1px 1px black",
                textOutline: "none",
              },
            },
          },
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  computed: {
    options() {
      let options = Object.assign(this.chartOptions, {});

      options.series = [];
      options.xAxis.categories = Object.assign(this.categories, {});
      options.series = Object.assign(this.barDataList, {});
      // options.series[0].data[i].tooltip.valueSuffix = " %";

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}

table {
  margin: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

td,
th {
  border: 1px solid #ccc;
  padding: 4px 20px;
}
</style>

