<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="chartOptions",
    style="width: 100%; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartPercentBar",
  props: {
    barDataList: Array,
    categories: Array,
    title: String,
    percent: Boolean,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: this.title,
        },
        xAxis: {
          categories: this.categories,
          labels: {
            formatter: function () {
              return "<b>" + this.value + "</b>";
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          crosshair: true,
          // labels: {
          //   format: "{value} %",
          // },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: this.percent
            ? '<span style="color:{point.color}">{point.name}</span> {series.name}: <b>{point.y}%'
            : '<span style="color:{point.color}">{point.name}</span> {series.name}: <b>{point.y}',
        },
        series: this.barDataList,
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        },

        plotOptions: {
          cursor: "pointer",

          column: {
            dataLabels: {
              enabled: true,
              color: "#1f1f1f",
              format: this.percent ? "{point.y:.1f}%" : "{point.y:.1f}",

              style: {
                // 如果不需要数据标签阴影，可以将 textOutline 设置为 'none'
                // textOutline: "1px 1px black",
                textOutline: "none",
              },
            },
          },
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  watch: {
    barDataList() {
      this.redraw();
    },
    categories() {
      this.redraw();
    },
    title() {
      this.redraw();
    },
    percent() {
      this.redraw();
    },
  },
  methods: {
    redraw() {
      this.chartOptions = {
        chart: {
          type: "column",
        },
        title: {
          text: this.title,
        },
        xAxis: {
          categories: this.categories,
          labels: {
            formatter: function () {
              return "<b>" + this.value + "</b>";
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          crosshair: true,
          // labels: {
          //   format: "{value} %",
          // },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span> {series.name}: <b>{point.y}',
        },
        series: this.barDataList,
        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        },

        plotOptions: {
          cursor: "pointer",

          column: {
            dataLabels: {
              enabled: true,
              color: "#1f1f1f",
              format: this.percent ? "{point.y:.1f}%" : "{point.y:.1f}",

              style: {
                // 如果不需要数据标签阴影，可以将 textOutline 设置为 'none'
                // textOutline: "1px 1px black",
                textOutline: "none",
              },
            },
          },
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}

table {
  margin: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

td,
th {
  border: 1px solid #ccc;
  padding: 4px 20px;
}
</style>

