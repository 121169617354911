<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="options",
    style="width: 100%; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartPie",
  props: {
    pieDataList: Array,
    pppList: Array,
  },
  data() {
    var vm = this;
    return {
      chartOptions: {
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        title: {
          text: null,
        },
        tooltip: {
          formatter: function () {
            return (
              this.series.name + ": <b>" + vm.pppList[this.point.index] + "</b>"
            );
          },
        },
        series: this.pieDataList,
        // dataLabels: {
        //   enabled: true,
        //   rotation: -90,
        //   color: "#FFFFFF",
        //   align: "right",
        //   format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        // },

        plotOptions: {
          cursor: "pointer",
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              // distance: -50,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              // style: {
              // 	color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
              // }
            },
          },
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  computed: {
    options() {
      let options = Object.assign(this.chartOptions, {});

      options.series = [];
      options.series = Object.assign(this.pieDataList, {});

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}

table {
  margin: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

td,
th {
  border: 1px solid #ccc;
  padding: 4px 20px;
}
</style>
