<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="chartOptions",
    style="width: 100%; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartScatterDoubleLine",
  props: {
    line1DataList: Array,
    line2DataList: Array,
    scatterDataList: Array,
    title: String,
  },
  data() {
    return {
      chartOptions: {
        xAxis: {
          max: this.line1DataList[1][0],
          min: this.line1DataList[0][0],
          gridLineWidth: 1,
          title: {
            text: "Offensive points added",
          },
          showFirstLabel: false,
        },
        yAxis: {
          max: this.line2DataList[1][1],
          min: this.line2DataList[0][1],
          title: {
            text: "Defensive points saved",
          },
          labels: {},
        },
        title: {
          text: this.title,
        },

        tooltip: {
          headerFormat: "<b>Offensive points added:</b> {point.x}<br/>",
          pointFormat: "<b>Defensive points saved:</b> {point.y}",
        },
        series: [
          {
            type: "line",
            data: this.line1DataList,
            color: "#979797",
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            enableMouseTracking: false,
          },
          {
            type: "line",
            data: this.line2DataList,
            color: "#979797",
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            enableMouseTracking: false,
          },
          {
            type: "scatter",
            data: this.scatterDataList,
            color: "#487AA4",
            marker: {
              enabled: false,
            },
          },
        ],
        plotOptions: {
          scatter: {
            color: "487AA4",

            dataLabels: {
              enabled: true,
              format: "{point.name:.1f}",
              color: "#487AA4",
            },
          },
        },
        legend: {
          enabled: false,
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  watch: {
    line1DataList() {
      this.redraw();
    },
    line2DataList() {
      this.redraw();
    },
    scatterDataList() {
      this.redraw();
    },
  },
  methods: {
    redraw() {
      this.chartOptions = {
        xAxis: {
          max: this.line1DataList[1][0],
          min: this.line1DataList[0][0],
          gridLineWidth: 1,
          title: {
            text: "Offensive points added",
          },
          showFirstLabel: false,
        },
        yAxis: {
          max: this.line2DataList[1][1],
          min: this.line2DataList[0][1],
          title: {
            text: "Defensive points saved",
          },
          labels: {},
        },
        title: {
          text: this.title,
        },

        tooltip: {
          headerFormat: "<b>Offensive points added:</b> {point.x}<br/>",
          pointFormat: "<b>Defensive points saved:</b> {point.y}",
        },
        series: [
          {
            type: "line",
            data: this.line1DataList,
            color: "#979797",
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            enableMouseTracking: false,
          },
          {
            type: "line",
            data: this.line2DataList,
            color: "#979797",
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            enableMouseTracking: false,
          },
          {
            type: "scatter",
            data: this.scatterDataList,
            color: "#487AA4",
            marker: {
              enabled: false,
            },
          },
        ],
        plotOptions: {
          scatter: {
            color: "487AA4",

            dataLabels: {
              enabled: true,
              format: "{point.name:.1f}",
              color: "#487AA4",
            },
          },
        },
        legend: {
          enabled: false,
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}
</style>

