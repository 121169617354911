<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="options",
    style="width: 100%; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartScatterLine",
  props: {
    lineDataList: Array,
    scatterDataList: Array,
    title: String,
  },
  data() {
    return {
      chartOptions: {
        // chart: {
        //   renderTo: "container",
        //   zoomType: "xy",
        //   events: {
        //     load: function () {
        //       this.StaggerDataLabels(this.series);
        //     },
        //     redraw: function () {
        //       var series = this.series;
        //       setTimeout(function () {
        //         this.StaggerDataLabels(series);
        //       }, 1000);
        //     },
        //   },
        //   type: "bar",
        //   width: 1000,
        // },
        xAxis: {
          min: 0,
          max: 20,

          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "進攻效率",
          },
        },
        title: {
          text: this.title,
        },
        series: [
          {
            type: "line",
            data: this.lineDataList,
            color: "#979797",
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            enableMouseTracking: false,
          },
          {
            type: "scatter",
            data: this.scatterDataList,
            color: "#487AA4",
            marker: {
              radius: 4,
            },
          },
        ],
        plotOptions: {
          scatter: {
            color: "487AA4",

            dataLabels: {
              enabled: true,
              // allowOverlap: true,
              // overflow: "allow",
              format: "{point.name}",
              color: "#487AA4",
            },
          },
        },
        tooltip: {
          headerFormat: null,
          pointFormat:
            "{point.name}<br><b>USG:</b> {point.x}%<br/><b>進攻效率:</b> {point.y}",
        },
        legend: {
          enabled: false,
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  computed: {
    options() {
      let options = Object.assign(this.chartOptions, {});
      options.title = this.title;
      options.series[0].data = Object.assign(this.lineDataList, {});
      options.series[1].data = Object.assign(this.scatterDataList, {});

      let xMax = 0;
      let yMax = 0;

      for (let index = 0; index < this.scatterDataList.length; index++) {
        let x = parseInt(this.scatterDataList[index].x);

        if (x > xMax) {
          xMax = x;
        }

        let y = parseInt(this.scatterDataList[index].y);
        if (y > yMax) {
          yMax = y;
        }
      }

      options.xAxis.max = xMax + 1;
      options.yAxis.max = yMax + 0.5;

      return options;
    },
  },
  // methods: {
  //   StaggerDataLabels(series) {
  //     let sc = series.length;
  //     if (sc < 2) return;

  //     for (let s = 1; s < sc; s++) {
  //       var s1 = series[s - 1].points,
  //         s2 = series[s].points,
  //         l = s1.length,
  //         diff,
  //         h;

  //       for (let i = 0; i < l; i++) {
  //         if (s1[i].dataLabel && s2[i].dataLabel) {
  //           diff = s1[i].dataLabel.y - s2[i].dataLabel.y;
  //           h = s1[i].dataLabel.height + 2;

  //           if (this.isLabelOnLabel(s1[i].dataLabel, s2[i].dataLabel)) {
  //             if (diff < 0)
  //               s1[i].dataLabel.translate(
  //                 s1[i].dataLabel.translateX,
  //                 s1[i].dataLabel.translateY - (h + diff)
  //               );
  //             else
  //               s2[i].dataLabel.translate(
  //                 s2[i].dataLabel.translateX,
  //                 s2[i].dataLabel.translateY - (h - diff)
  //               );
  //           }
  //         }
  //       }
  //     }
  //   },
  //   isLabelOnLabel(a, b) {
  //     var al = a.x - a.width / 2;
  //     var ar = a.x + a.width / 2;
  //     var bl = b.x - b.width / 2;
  //     var br = b.x + b.width / 2;

  //     var at = a.y;
  //     var ab = a.y + a.height;
  //     var bt = b.y;
  //     var bb = b.y + b.height;

  //     if (bl > ar || br < al) {
  //       return false;
  //     } //overlap not possible
  //     if (bt > ab || bb < at) {
  //       return false;
  //     } //overlap not possible
  //     if (bl > al && bl < ar) {
  //       return true;
  //     }
  //     if (br > al && br < ar) {
  //       return true;
  //     }

  //     if (bt > at && bt < ab) {
  //       return true;
  //     }
  //     if (bb > at && bb < ab) {
  //       return true;
  //     }

  //     return false;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}
</style>
