<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="chartOptions",
    style="width: 100%; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartBar",
  props: {
    trendDataList: Array,
    categories: Array,
    title: String,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "area",
          zoomType: "x",
          panning: true,
          panKey: "shift",
        },
        title: {
          text: this.title,
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "滑鼠拖動可以進行縮放"
              : "手勢操作進行縮放",
        },
        xAxis: {
          categories: this.categories,
        },
        yAxis: {
          title: {
            text: "分差",
          },
          crosshair: true,
          // labels: {
          //   format: "{value} %",
          // },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "分差：{point.y}",
        },

        series: [
          {
            data: this.trendDataList,

            marker: {
              enabled: false,
            },
            threshold: null,
          },
        ],
        // dataLabels: {
        //   enabled: true,
        //   rotation: -90,
        //   color: "#FFFFFF",
        //   align: "right",
        //   format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        // },

        plotOptions: {
          area: {
            fillColor: {},
            lineWidth: 2,
            lineColor: "#487AA4",
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },

        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  watch: {
    trendDataList() {
      this.redraw();
    },
    categories() {
      this.redraw();
    },
  },
  methods: {
    redraw() {
      this.chartOptions = {
        chart: {
          type: "area",
          zoomType: "x",
          panning: true,
          panKey: "shift",
        },
        title: {
          text: this.title,
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "滑鼠拖動可以進行縮放"
              : "手勢操作進行縮放",
        },
        xAxis: {
          categories: this.categories,
        },
        yAxis: {
          title: {
            text: "分差",
          },
          crosshair: true,
          // labels: {
          //   format: "{value} %",
          // },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "分差：{point.y}",
        },

        series: [
          {
            data: this.trendDataList,

            marker: {
              enabled: false,
            },
            threshold: null,
          },
        ],
        // dataLabels: {
        //   enabled: true,
        //   rotation: -90,
        //   color: "#FFFFFF",
        //   align: "right",
        //   format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        // },

        plotOptions: {
          area: {
            fillColor: {},
            lineWidth: 2,
            lineColor: "#487AA4",
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },

        dataLabels: {
          enabled: true,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // :.1f 为保留 1 位小数
        },
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}

table {
  margin: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

td,
th {
  border: 1px solid #ccc;
  padding: 4px 20px;
}
</style>

